<template>
	<div class="main-wrap">
		<MainMenu :list="[{title:L('投稿信息'),path:'/User/Home'},{title:L('上传作品'),path:this.$route.path}]"/>
		<div id="Page-User-Work" class="w720">
			<div class="title-h2">WORK INFO</div>
			<div class="title-h1">{{L("投稿作品信息")}}</div>
			<div class="mt-20"></div>
			<template v-if="dataLoading">
				<img src="@/assets/common/loading_d.gif" />
			</template>
			<template v-else>
				<div class="form-box baseInfo">
					<div class="form-item title">
						<template v-if="$root.lang=='CN'">
							<div class="label">{{L("作品名称")}}<span>*</span></div>
							<div class="form-item"  :class="{error:!formRule.title}">
								<div class="label">{{L("中文名称（建议附上英文译名，以便评委评审）")}}</div>
								<div class="input"><input maxlength="200" placeholder="" type="text" v-model="formData.title" @change="checkEmpty('title')" ref="title" name="title" autocomplete="off"/></div>
							</div>
						</template>
						<template v-if="$root.lang=='EN'">
							<div class="form-item" :class="{error:!formRule.title}">
								<div class="label">In English</div>
								<div class="input"><input maxlength="200" placeholder="" type="text" v-model="formData.title" @change="checkEmpty('title')" ref="title" name="title" autocomplete="off"/></div>
							</div>
						</template>
					</div>
					
					<div class="form-item awardType">
						<div class="label">{{L("作品类别")}}<span>*</span><span class="form-tips" v-if="this.$root.lang=='CN'">（{{L("九选一")}}）</span></div>
						<div class="form-item"  :class="{error:!formRule.awardType}">
							<div class="radioGroup flex top middle wrap direction">
								<!-- <label v-for="item in $root.awardTypeList" :key="item.id" :class="{disabled:isEdit}"><input :disabled="isEdit" type="radio" @change="doAwardTypeChange($event,item)" name="awardType" :value="item.id" v-model="formData.awardType"/>{{item.code}}.{{item.name}}</label> -->
								<label v-for="item in $root.awardTypeList" :key="item.id" :class="{disabled:isEdit}"><input :disabled="isEdit" type="radio" @change="doAwardTypeChange($event,item)" name="awardType" :value="item.id" v-model="formData.awardType"/>{{item.code}}. {{$root.lang=='CN'?L(item.name):item.ename}}</label>
							</div>
						</div>
					</div>
					<div class="form-item year">
						<div class="label">{{L("创作年份")}}<span>*</span><span class="form-tips" v-if="this.$root.lang=='CN'">（{{L("二选一")}}）</span></div>
						<div class="form-item"  :class="{error:!formRule.year}">
							<div class="radioGroup flex top middle ">
								<label v-for="year in $root.CONFIG.yearList" :key="year"><input type="radio" name="year" :value="year" v-model="formData.year"/>{{year}}</label>
							</div>
						</div>
					</div>
					<div class="form-item creators" :class="{error:!formRule.creators}">
						<div class="label">{{L("主创人员名单")}}<span>*</span><span class="form-tips">（{{L("至少填写一项，最多填写6项，并确保所填信息准确")}}）</span></div>
						<div class="list" v-if="$root.creatorList.length">
							<div v-for="(item,index) in $root.creatorList" :key="item.code" class="flex middle ">
								<div class="code">{{item.code}}</div>
								<div class="name">{{$root.lang=='CN'?L(item.name):item.ename}}</div>
								<label class="input"><input maxlength="200" placeholder="" type="text" v-model="formData.creators[index].value" ref="creators" :name="'creators_'+index" autocomplete="off"/></label>
							</div>
						</div>
					</div>
					
					<div class="form-item description">
						<div class="label">{{L("项目介绍")}}<span>*</span></div>
						<template v-if="$root.lang=='CN'">
							<div class="form-item"  :class="{error:!formRule.description}">
								<div class="label">{{L("中文介绍")}}<span class="form-tips">（{{L("2000字符以内，建议附上英文简介，以便评委评审")}}）</span></div>
								<div class="input"><textarea maxlength="2000" type="text" v-model="formData.description" @change="checkEmpty('description')" ref="description" name="description" autocomplete="off" spellcheck="true"/></div>
							</div>
						</template>
						<template v-if="$root.lang=='EN'">
							<div class="form-item" :class="{error:!formRule.description}">
								<div class="label">In English<span class="form-tips">(200 words maximum)</span></div>
								<div class="input"><textarea maxlength="2000" type="text" v-model="formData.description" @change="checkEmpty('description')" ref="description" name="description" autocomplete="off" spellcheck="true"/></div>
							</div>
						</template>
					</div>
					<div class="form-item ">
						<div class="label">{{L("作品图片")}}<span>*</span></div>
						<div class="description">{{L("上传图片1~10张，且第一张默认为封面图片，格式可为JPG/JPEG/PNG（RGB模式，300dpi），尺寸不限，单一图片大小请勿超过10MB。（单击拖动图片可调整图片顺序）")}}</div>
						<div class="mt-20"></div>
						<div class="upload-box"><UploadBox ref="upload_images" :uid="userData.id" accept="image/png,image/jpeg,image/jpg" :size="10"></UploadBox></div>
					</div>
					<div class="form-item " v-show="isShowVideo">
						<div class="label">{{L("作品视频")}}<span class="form-tips">（{{L("选择上传")}}）</span></div>
						<div class="description">{{L("C、E、H类别最多可上传1个视频，时长不超过3分钟，大小不超过50MB，格式为MP4（H.264视频编码和AAC音频编码）或GIF动态图片")}}</div>
						<div class="mt-20"></div>
						<div class="upload-box"><UploadBox ref="upload_videos" :max="2" :uid="userData.id" accept="audio/*,video/*,application/ogg,audio/ogg,image/gif" :size="50"></UploadBox></div>
					</div>
					
					<div class="form-btn">
						<div class="flex right">
							<div><span class="button dark" :class="{loading}" @click="doSubmit">
								<template v-if="isEdit">{{L("确认修改")}}</template>
								<template v-else>{{L("确认投稿")}}</template>
							</span></div>
						</div>
					</div>
				</div>
			</template>
			
			
		</div>
		
		<transition name='fade'>
			<div class="ruleBoxBg" v-if="isShowRuleBox">
				<div class="ruleBox">
					<div class="header flex middle wrap">
						<div class="title-h2">TERMS AND CONDITIONS</div>
						<div class="title-h1">{{L("条例细则")}}</div>
					</div>
					<div class="content" v-html="ruleData"></div>
					<div class="footer flex right">
						<div class="button gray" @click="closeRuleBox">{{L('不同意')}}</div>
						<div class="button dark" @click="doSubmit">{{L('同意')}}</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
  
</template>

<script>
import MainMenu from '/src/views/MainMenu';
import UploadBox from '/src/components/UploadBox.vue';


export default {
  name: 'Page-User-Work',
  components: {
    MainMenu,
		UploadBox
  },
  data(){
		
    return {
			formRule:{
				title:true,
				etitle:true,
				awardType:true,
				year:true,
				creators:true,
				description:true,
				edescription:true,
			},
			// formData:{"creators":[{"key":"DA|设计公司","value":"设计公司设计公司设计公司设计公司"},{"key":"CD|创意指导","value":"创意指导创意指导创意指导创意指导"},{"key":"AD|艺术指导","value":"艺术指导艺术指导艺术指导艺术指导"},{"key":"DD|设计指导","value":""},{"key":"DS|设计师","value":""},{"key":"AT|建筑师","value":""},{"key":"ID|室内设计师","value":""},{"key":"MD|动态设计师","value":""},{"key":"WD|网页设计师","value":""},{"key":"TG|字体设计师","value":""},{"key":"CW|文案","value":""},{"key":"ST|策略师","value":""},{"key":"SL|造型师","value":""},{"key":"AR|艺术家","value":""},{"key":"IR|插画师","value":""},{"key":"PM|项目经理","value":""},{"key":"PR|制作","value":""},{"key":"DE|开发","value":""},{"key":"CO|统筹","value":""},{"key":"PG|摄影","value":""},{"key":"CL|客户","value":""}],"title":"测试中文名称","etitle":"测试英文名称","awardType":2,"year":"2022","description":"中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文版介绍中文","edescription":"英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍英文版介绍","images":[],"videos":[]},
			formData:{
				
				creators:[],
				title:"",
				etitle:"",
				awardType:0,
				year:this.$root.CONFIG.yearList&&this.$root.CONFIG.yearList.length&&this.$root.CONFIG.yearList[0]||"",
				description:"",
				edescription:"",
				
				images:[],
				videos:[],
				
			},
			userData:{},
			isEdit:false,
			loading:false,
			dataLoading:true,
			isShowVideo:false,
			isSubmited:false,
			isShowRuleBox:false,
			ruleData:"",
			list:[]
    };
  },
  async mounted () {
		if(!this.$root.CONFIG.onSubmit){
			alert(this.L("作品征集已结束，请留意官网最新资讯"));
			this.$root.toPage("/User/Home");
			return;
		} else {
			
			var list = await this.request.get("/api/v1/awardWork/web/getCurrentUserWork");
			this.list = list || [];
			var userData = await this.request.get("/api/v1/frontDeskUser/getCurrent");
			if(userData && userData.id){
				if(userData.config){
					try{
						userData.config = JSON.parse(userData.config);
					}catch(e){
						userData.config = {};
					}
				}
				this.$set(this,'userData',userData);	
			}
			
			// 作品类别
			var awardTypeList = this.$root.awardTypeList;
			this.formData.awardType = awardTypeList.length? awardTypeList[0].id:0;
			
			
			// 主创人员名单
			var creatorList = this.$root.creatorList;
			this.formData.creators = creatorList.map(item=>{ return {key:item.code+"|"+item.name,value:""} })
			
			
			// 检验是否已完成投稿信息
			if(!userData||!userData.config||!userData.config.unitName){
				alert(this.L("请先完善投稿信息"));
				this.$root.toPage("/User/Home");
			}
			
			// 编辑校验
			if(this.$route.path == '/User/Work/Edit'){
				if(!this.$route.params.id){
					this.$root.toPage('/User/Home');
					return ;
				}
				this.isEdit = true;
				if(!list){list = [];}
				let target = list.find(item=>item.id == this.$route.params.id);
				if(!target){
					alert(this.L("操作无效"));
					this.$root.toPage('/User/Home');
					return ;
				}
				target = this.$root.copy(this.$route.params);
				
				let creators = target.content.creators;
				delete target.content.creators;
				
				for(let key in target.content){
					if(typeof this.formData[key] != 'undefined'){
						this.formData[key] = target.content[key];
					}
				}
				
				creators.forEach(item =>{
					for(let i=0,l=this.formData.creators.length;i<l;i++){
						if(item.key == this.formData.creators[i].key){
							this.formData.creators[i].value = item.value;
							break;
						}
					}
				});
				let awardType = target.content.awardType;
				let awardTarget = awardTypeList.find(item => item.id == awardType);
				if(awardTarget && awardTarget.config && awardTarget.config.video){
					this.isShowVideo = true;
				}
				
				this.dataLoading = false;
				this.$nextTick(()=>{
					this.$refs.upload_images.updateList(this.formData.images);
					this.$refs.upload_videos.updateList(this.formData.videos);
				})
			} else {
				
				var last_word_data = window.localStorage.getItem("last_work_data");
				if(last_word_data){
					try{
						last_word_data = JSON.parse(last_word_data);
					}catch(e){
						last_word_data = false;
					}
				}
				if(last_word_data){
					let target = {content:last_word_data};
					let creators = target.content.creators;
					delete target.content.creators;
					
					for(let key in target.content){
						if(typeof this.formData[key] != 'undefined'){
							this.formData[key] = target.content[key];
						}
					}
					
					creators.forEach(item =>{
						for(let i=0,l=this.formData.creators.length;i<l;i++){
							if(item.key == this.formData.creators[i].key){
								this.formData.creators[i].value = item.value;
								break;
							}
						}
					});
					let awardType = target.content.awardType;
					let awardTarget = awardTypeList.find(item => item.id == awardType);
					if(awardTarget && awardTarget.config && awardTarget.config.video){
						this.isShowVideo = true;
					}
					this.dataLoading = false;
					this.$nextTick(()=>{
						this.$refs.upload_images.updateList(this.formData.images);
						this.$refs.upload_videos.updateList(this.formData.videos);
					})
				}else {
					this.dataLoading = false;
				}
			}
		}
		
		
		window.addEventListener('beforeunload', e => this.saveWorkData(e))
		// this.showRuleBox()
		
  },
	beforeDestroy() {
		this.beforeunloadHandler();
		window.removeEventListener('beforeunload', e => this.saveWorkData(e))
	},
  methods: {
		doAwardTypeChange($event,item){
			if(item.config && item.config.video){
				this.isShowVideo = true;
			} else {
				this.isShowVideo = false;
			}
		},
		checkEmpty(target){
			if(!this.formData[target]){
				this.formRule[target] = false;
				this.$refs[target].focus();
				return false;
			}
			this.formRule[target] = true;
			return true
		},
		checkCreators(){
			var cnum = 0;
			this.formData.creators.forEach(item=>{
				if(item.value){cnum++;}
			});
			if(cnum==0){
				this.$refs.creators[0].focus();
				return false;
			}
			if(cnum > 6){
				alert(this.L("主创人员名单 最多填写6项"));
				return false;
			}
			return true;
		},
		saveWorkData(){
			if(!this.isEdit){
				if(!this.isSubmited){
					console.log("beforeunloadHandler")
					var data = this.$root.copy(this.formData);
					data.creators = this.formData.creators.filter(item=>item.value);
					data.images = this.$refs.upload_images.getList() || [];
					data.videos = this.$refs.upload_videos.getList() || [];
					window.localStorage.setItem("last_work_data",JSON.stringify(data));
				} else {
					window.localStorage.removeItem("last_work_data");
				}
			}
		},
		beforeunloadHandler(e) {
			if(!this.isEdit){
				if(!this.isSubmited){
					var wcr = window.confirm(this.L("是否保存该页面信息?"));
					if(wcr){
						console.log("beforeunloadHandler")
						this.saveWorkData();
					} else {
						window.localStorage.removeItem("last_work_data");
					}
					
				} else {
					window.localStorage.removeItem("last_work_data");
				}
				
			}
			
		},
		async doSubmit(){
			
			var isOk = true;
			var arr =  [
				"title",
				"awardType",
				"year",
				"description",
			];
			// if(this.$root.lang=='CN'){
			// 	arr = [
			// 		"title",
			// 		"awardType",
			// 		"year",
			// 		"description",
			// 	];
			// } else {
			// 	arr = [
			// 		"etitle",
			// 		"awardType",
			// 		"year",
			// 		"edescription",
			// 	];
			// }
			
			for(let i=0;i<arr.length;i++){
				if(!this.checkEmpty(arr[i])){isOk = false;break ;}
			}
			if(!isOk)return ;
			
			
			if(!this.checkCreators()){
				isOk = false;
			}
			if(!isOk)return ;
			var creators = this.formData.creators.filter(item=>item.value);
			
			var images = this.$refs.upload_images.getList();
			if(!images || images.length<1){
				alert(this.L("作品图片至少上传1张"));
				return;
			}
			
			
			if(!this.isShowRuleBox && this.list.length == 0){
				this.showRuleBox();
				return ;
			}
			this.closeRuleBox();
				
			
			
			var videos = [];
			
			if(!this.isShowVideo){
				videos = [];
			} else {
				videos = this.$refs.upload_videos.getList();
			}
			
			this.loading = true;
			let url = '/api/v1/awardWork/web/create';
			let postData = {
				"content":JSON.stringify( {
					...this.formData,images,videos,creators
				} ),
				"awardStage": this.$root.CONFIG.mainAward, // 赛期
				"awardType": this.formData.awardType, // 奖项类别
				"awardWorkStatus": 1, // 作品初始状态
				"groupType": 0 // 参与奖项
			};
			if(this.isEdit){
				url = '/api/v1/awardWork/web/update';
				postData.id = this.$route.params.id;
				delete postData.awardWorkStatus
			}
			
			this.request.post(url,postData).then(result=>{
				this.loading = false;
				this.isSubmited = true;
				if(result && result.status == 1){
					this.$root.toPage("Message",{
						title:this.L("投稿成功"),
						description:`
							<p>${this.L("稿件已经提交成功！")}</p>
							<p>${this.L("如果投稿失败请尝试重新投稿")}</p>
							<p>${this.L("或者联系客服：apd@sandupublishing.com")}</p>
						`,
						backPath:'/User/Home'
					});
					
					// this.isEdit && alert("作品保存成功!");
					// !this.isEdit && alert("作品提交成功!");
					// this.$root.toPage("/User/Home");
				}
			})
			
			
		},
		async showRuleBox(){
			if(!this.ruleData){
				var list = await this.request.zd.get("INTRO");
				if(!list) list = [];
				var ruleTarget = list.find(item=>item.title == '条例细则');
				if(ruleTarget){
					let ruleData = this.$root.lang == 'CN' ? this.L(ruleTarget.content) : ruleTarget.econtent;
					ruleData = ruleData.replace(/<br\/><br\/>/g,"<div class=\"mt-10\"></div>").replace(/<p><\/p>/g,"<div class=\"mt-5\"></div>").replace(/<p><br\/>/g,"<div class=\"mt-10\"></div>");
					
					this.ruleData = ruleData
					
				}
			}
			this.isShowRuleBox = true;
			document.body.parentElement.className = "ofhidden"
		},
		closeRuleBox(){
			this.isShowRuleBox = false;
			document.body.parentElement.className = document.body.parentElement.className.replace("ofhidden","");
		}
		
		
  }
}
</script>

<style scoped lang="less">
#Page-User-Work{
	.awardType,.year{
		.form-item{
			padding-bottom:6px;
			.radioGroup{
				margin-bottom:5px;
			}
		}
	}
	.awardType{
		.form-item{
			.radioGroup{
				height:86px;
			}
		}
	}
	.creators{
		.list{
			font-size:14px;
			&>div:nth-child(odd){
				&>div,&>label{
					background-color: #F2F2F3;
				}
			}
				
			&>div{
				
				&>div,&>label{
					padding:4px 20px 2px;
					line-height: 2;
				}
				
			}
			div.code{
				width:10%;
				flex-shrink: 0;
				margin-right:5px;
			}
			div.name{
				width:20%;
				flex-shrink: 0;
				margin-right:5px;
				padding:4px 0px 2px 36px;
			}
			label.input{
				width:100%;
				padding:4px 10px 2px;
				input{
					font-size:14px;
					line-height: 2;
					border:0;
					background-color: transparent;
				}
			}
		}
	}
}
.ruleBoxBg{
	background-color:rgba(0,0,0,.3);
	position: fixed;
	top:0;left:0;right:0;bottom:0;
	z-index:998;
	overflow: auto;
}
.ruleBox{
	width: 720px;
	max-width: calc(100vw - 90px);
	height: 800px;
	max-height: calc(100vh - 40px - 130px);
	position: absolute;
	left:50%;top:0;margin-top:130px;
	transform: translateX(-50%);
	z-index:999;
	background-color: #fff;
	border-radius: 30px;
	box-shadow: 0px 0px 2px rgba(255,255,255,.1);
	/deep/ul,/deep/ol{margin: 0 0 0 0;padding-left: 19px;}
	/deep/ul {
		padding-left: 2px;
		li{
			list-style-type: none;
			padding-left:19px;
			background:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2LjY2OSIgaGVpZ2h0PSI2LjY2OSIgdmlld0JveD0iMCAwIDYuNjY5IDYuNjY5Ij4KICA8ZyBpZD0i57uEXzE4NSIgZGF0YS1uYW1lPSLnu4QgMTg1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjAwLjM2IC0yOTA1Ljg0NSkiPgogICAgPGNpcmNsZSBpZD0i5qSt5ZyGXzEwMSIgZGF0YS1uYW1lPSLmpK3lnIYgMTAxIiBjeD0iMi44MzUiIGN5PSIyLjgzNSIgcj0iMi44MzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYwMC44NiAyOTA2LjM0NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzA0MDAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjEiLz4KICA8L2c+Cjwvc3ZnPgo=")  no-repeat left 7px ;
		}
	}
	padding:20px 50px;
	.header{
		height:66px;
		margin-bottom:20px;
		align-items:flex-end;
	}
	.title-h1{padding-bottom:0;width: 100%;}
	.title-h2{width: 100%;}
	.content{
		font-size:16px;
		height:calc(100% - 50px - 20px - 36px - 20px - 20px);
		padding-right:10px;
		overflow: scroll;
	}
	.footer{
		height:30px;
		font-size:16px;
		margin-top:20px;
		.button{line-height: 2;height:32px;}
	}
}
</style>