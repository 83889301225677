<template>
	<div>
		<div class="main-box-menu ">
			<div v-if="title" class="title b">{{L(title)}}</div>
			<div v-for="item in list" :key="item.id || item.path" class="flex middle ">
				<a class="a" :class="{active:path == item.path,unactive:!item.path}" href="javascript:void(0)" @click="item.click?item.click():toPage(item.path)">{{item.title}}</a>
			</div>
		</div>
	</div>
</template>

<script>


export default {
  name: 'MainMenu',
  props: {
    list:Array,
		title:{
			default:"",
			type:String
		}
  },
  data(){

    return {
			path:""
    }
  },
	watch: {
	  $route(){
			this.initMenu();
		}
	},
  mounted () {
    this.initMenu();
  },
  methods: {
		toPage(path){
			if(path){
				this.$root.toPage(path)
			}
		},
		initMenu(){
			this.path = this.$route.path;
		}
  }
}
</script>

<style lang="less" scoped>
.title{
	font-size:26px;
	margin-bottom:20px;
	margin-left:12px;
}
</style>
